import { useQuery } from '@tanstack/react-query'
import { useQueryString } from '@kaliber/sanity-routing/queryString'
import { useLocationMatch } from '@kaliber/routing'
import { useElementSize } from '@kaliber/use-element-size'
import { FilterHeader } from '/features/pageOnly/jobsOverview/FilterHeader'
import { handleResponse } from '/machinery/handleResponse'
import { useReportError } from '/machinery/ReportError'
import { useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'
import { mapValuesToIdsFromFilterArray } from '/machinery/mapValuesToIds'
import { ensureInteger, ensureString, ensureArray, ensureBoolean, ensureNumber } from '/machinery/ensureValue'
import { useDebounced } from '/machinery/useDebounced'
import { Filters } from './Filters'
import { isNotHoursPerWeekDefaultValue } from './isNotHoursPerWeekDefaultValue'
import { Results } from './Results'
import { useScrollActivity } from '/machinery/useScrollActivity'
import { trackFilter } from '/machinery/tracking/pushToDataLayer'

import cssStyleContext from '/cssGlobal/style-context.css'
import styles from './JobsListAndFilter.css'

export function JobsListAndFilter({ lateNight, initialJobsAndFilters, isLoggedIn, country }) {
  const { initialJobs, filters } = initialJobsAndFilters

  const { params: { language } } = useLocationMatch()
  const [queryString, setQueryString] = useQueryString()
  const setQueryStringDebounced = useDebounced(setQueryString)

  const normalizedValues = useNormalizedValues(queryString)

  const [lastUpdatedFilter, setLastUpdatedFilter] = React.useState(undefined)

  const { jobs, aggregations, totalJobs, isError, isSuccess, isFetching } = useFetchJobs({
    lastUpdatedFilter, normalizedValues, initialJobs, language, filters
  })

  const filtersAndValues = useFiltersWithValuesAndTotals({
    filters,
    values: normalizedValues,
    aggregations,
  })

  const { ref, hasSurpassedHeight } = useHasSurpassedHeight()

  const { direction } = useScrollActivity({ delta: 100 })
  const shouldSlideDown = hasSurpassedHeight && (direction === 'up')

  return (
    <div
      className={styles.component}
      data-style-context={lateNight ? cssStyleContext.contextBlack : cssStyleContext.contextWhite}
    >
      <div {...{ ref }}>
        <FilterHeader
          onFilterChange={handleFilterChange}
          locationTypeOptions={filtersAndValues.locationType.options}
          textQuery={normalizedValues.textQuery}
          locationType={normalizedValues.locationType}
          {...{ country }}
        />
      </div>

      <Filters
        useTopOffset={hasSurpassedHeight}
        onResetClick={handleResetClick}
        layoutClassName={cx(styles.filtersLayout, shouldSlideDown && styles.isSlidDown)}
        onFilterChange={handleFilterChange}
        {...{ totalJobs, filtersAndValues, isLoggedIn, lateNight, shouldSlideDown }}
      />

      <Results
        page={normalizedValues.page}
        onLoadMoreJobs={handleLoadMoreJobs}
        showJobType={normalizedValues.locationType === 'all'}
        {...{ lateNight, jobs, totalJobs, isError, isSuccess, isFetching }}
      />
    </div>
  )

  function handleResetClick() {
    setQueryString({})
  }

  function handleLoadMoreJobs() {
    setQueryString(x => ({ ...x, page: normalizedValues.page + 1 }))
  }

  function handleFilterChange(filterChanges) {
    const lastUsedKeys = Object.keys(filterChanges)
    const lastUsedKey = lastUsedKeys[lastUsedKeys.length - 1]

    setLastUpdatedFilter( lastUsedKey )

    const updateFunction = hasChangeThatShouldBeDebounced(filterChanges)
      ? setQueryStringDebounced
      : setQueryString

    updateFunction(x =>
      locationTypeChanged(x.locationType, filterChanges.locationType)
        ? {
          distanceInKm: x.distanceInKm,
          locationType: filterChanges.locationType,
          isInternal: x.isInternal
        }
        : {
          ...x,
          ...filterChanges,
          locationQuery: null,
          page: null
        }
    )

    function hasChangeThatShouldBeDebounced(o) {
      return 'hoursPerWeekMin' in o || 'hoursPerWeekMax' in o || 'textQuery' in o
    }

    function locationTypeChanged(a, b) {
      return a && b && a !== b
    }
  }
}

function useHasSurpassedHeight() {
  const [surpassed, setSurpassed] = React.useState(false)
  const { ref, size: { height } } = useElementSize()

  React.useEffect(
    () => {
      onScroll()

      window.addEventListener('scroll', onScroll)

      return () => {
        window.removeEventListener('scroll', onScroll)
      }

      function onScroll() {
        setSurpassed(window.scrollY >= height)
      }
    },
    [height]
  )

  return { ref, hasSurpassedHeight: surpassed }
}

function useFetchJobs({ lastUpdatedFilter, normalizedValues, initialJobs, language, filters }) {
  const reportError = useReportError()
  const placeholderData = React.useRef(initialJobs)

  const { data, isError, isSuccess, isFetching } = useQuery({
    queryKey: ['search', normalizedValues],
    queryFn: () => fetchJobs({ values: normalizedValues, language, filters, lastUpdatedFilter, data: placeholderData }),
    retryOnMount: false,
    placeholderData: placeholderData.current ? placeholderData.current : {},
    onSuccess: data => {
      placeholderData.current = data
    },
    onError: reportError
  })

  const totalJobs = data?.hits?.total?.value ?? null
  const jobs = data?.hits?.hits ?? []
  const aggregations = data?.aggregations

  return { jobs, aggregations, totalJobs, isError, isSuccess, isFetching }
}

async function fetchJobs({ values, language, filters, lastUpdatedFilter, data }) {
  const {
    page,
    locationType,
    careerLevel,
    department,
    role,
    textQuery,
    hoursPerWeekMin,
    hoursPerWeekMax,
    isInternal,
    typeOfShop,
    lat,
    lon,
    distanceInKm,
    locationName,
  } = values

  const response = await fetch(routeMap.api.v1.jobs(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify({
      role: mapValuesToIdsFromFilterArray(role, filters.roles),
      department: mapValuesToIdsFromFilterArray(department, filters.departments),
      locationType,
      isInternal,
      typeOfShop: mapValuesToIdsFromFilterArray(typeOfShop, filters.typeOfShops),
      lat,
      lon,
      locationName,
      distanceInKm,
      textQuery,
      ...(isNotHoursPerWeekDefaultValue(hoursPerWeekMin, hoursPerWeekMax) &&
        { hoursPerWeekMin, hoursPerWeekMax }
      ),
      careerLevel,
      language,
      page
    })
  })

  const result = await handleResponse(response)

  lastUpdatedFilter && trackFilter({
    resultcount: result?.hits?.total?.value || 0,
    searchterm: textQuery ?? null,
    ...((locationType && locationType !== 'all') && { locationType }),
    ...(lastUpdatedFilter && { filterName: lastUpdatedFilter }),
  })

  return result
}

function useFiltersWithValuesAndTotals({ filters, values, aggregations }) {
  const { __ } = useTranslate()

  return {
    locationType: optionsWithLabelAndValue('locationTypes', 'locationType'),
    distanceInKm: optionsWithLabelAndValue('distancesInKm', 'distanceInKm'),
    careerLevel: optionsWithLabelAndValue('careerLevels', 'careerLevel', totalFromValue),
    locationName: optionsWithValue('locationName', 'locationName', totalFromValue),
    ...(filters.departments && {
      department: optionsWithValue('departments', 'department', totalFromIds)
    }),
    role: optionsWithValue('roles', 'role', totalFromIds),
    isInternal: optionsWithLabelAndValue('isInternal', 'isInternal'),
    typeOfShop: optionsWithLabelAndValue('typeOfShops', 'typeOfShop', totalFromIds),
    hoursPerWeekMin: value(values.hoursPerWeekMin),
    hoursPerWeekMax: value(values.hoursPerWeekMax),
    lat: value(values.lat),
    lon: value(values.lon),
    customPlaceLabel: value(values.customPlaceLabel)
  }

  function totalFromIds({ valueName, option }) {
    return option?.ids?.reduce((result, id) => result + (aggregations?.[valueName]?.[id] || 0), 0) || 0
  }
  function totalFromValue({ valueName, option }) {
    return aggregations?.[valueName]?.[option.value] || 0
  }
  function optionsWithLabelAndValue(filterName, valueName, getTotal = null) {
    const options = filters?.[filterName] || []
    const value = values?.[valueName]

    return {
      options: options.map(option => ({
        ...option,
        label: __`filter-${filterName}-${option.value}`,
        total: getTotal?.({ valueName, option })
      })),
      value
    }
  }

  function optionsWithValue(filterName, valueName, getTotal = null) {
    const options = filters[filterName].map(option => ({
      ...option,
      total: getTotal?.({ valueName, option })
    }))
    const value = values[valueName]

    return { options, value }
  }
  function value(value) { return { value } }
}

function useNormalizedValues(queryString) {
  return React.useMemo(
    () => {
      return {
        page: ensureInteger(queryString.page, 1),
        textQuery: ensureString(queryString.textQuery),
        locationType: ensureString(queryString.locationType, 'all'),
        careerLevel: ensureArray(queryString.careerLevel),
        department: ensureArray(queryString.department),
        locationName: ensureArray(queryString.locationName),
        role: ensureArray(queryString.role),
        typeOfShop: ensureArray(queryString.typeOfShop),
        hoursPerWeekMin: ensureInteger(queryString.hoursPerWeekMin, 0),
        hoursPerWeekMax: ensureInteger(queryString.hoursPerWeekMax, 40),
        distanceInKm: ensureString(queryString.distanceInKm),
        lat: ensureNumber(queryString.lat, 0),
        lon: ensureNumber(queryString.lon, 0),
        isInternal: ensureBoolean(queryString.isInternal),
        customPlaceLabel: ensureString(queryString.customPlaceLabel),
      }
    },
    [queryString]
  )
}
