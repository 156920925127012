import { FloatingOverlay as FloatingOverlayBase, FloatingPortal, useFloating, useDismiss, useClick, useInteractions, useTransitionStyles } from '@floating-ui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useGeoLocation } from '/machinery/useGeoLocation'
import { useMediaQuery } from '@kaliber/use-media-query'
import { Icon } from '/features/buildingBlocks/Icon'
import { Button } from '/features/buildingBlocks/Button'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { translateSingular } from '/i18n/translations'
import { useQueryString } from '@kaliber/sanity-routing/queryString'
import { FilterFieldRadioGroup, FilterFieldCheckboxGroup, FilterFieldRange } from '/features/pageOnly/jobsOverview/FilterField'
import { SelectedFilterBar } from '/features/pageOnly/jobsOverview/SelectedFilterBar'
import { isNotHoursPerWeekDefaultValue } from './isNotHoursPerWeekDefaultValue'
import { routeMap } from '/routeMap'
import { TextFieldWithAutocomplete } from '/features/buildingBlocks/TextFieldWithAutocomplete'
import { handleResponse } from '/machinery/handleResponse'
import { useReportError } from '/machinery/ReportError'
import { useDebounce } from '/machinery/useDebounce'
import { useIsMountedRef } from '@kaliber/use-is-mounted-ref'
import { floatingFiltersId, useFloatingContentRoot } from '/machinery/FloatingContent'

import iconButton from '/images/icons/plus.raw.svg'

import cssStyleContext from '/cssGlobal/style-context.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './Filters.css'

export function Filters({ useTopOffset, totalJobs, filtersAndValues, onFilterChange, onResetClick, isLoggedIn, lateNight, shouldSlideDown, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const isDesktop = useMediaQuery(mediaStyles.viewportMd)
  const language = useLanguage()
  const [isOpen, setIsOpen] = React.useState(false)

  const filtersAndValueGroups = groupFiltersAndValues(filtersAndValues)
  const tags = createTagsData(filtersAndValues, language)
  const hasFilters = Boolean(tags.length)

  const root = useFloatingContentRoot({ id: floatingFiltersId })

  const props = { useTopOffset, totalJobs, filtersAndValueGroups, onFilterChange, onResetClick, tags, isLoggedIn, lateNight, root, isOpen, shouldSlideDown }

  return (
    <>
      {isViewportMd
        ? <DesktopFilters {...props} onRemoveTag={handleRemoveTag} onOpenChange={setIsOpen} {...{ layoutClassName }}  />
        : <MobileFilters {...props} onOpenChange={setIsOpen} />
      }
      {!isDesktop && (
        <>
          <div className={styles.filterButtonWrapper}>
            <FiltersOpenCloseButtonWithDot
              active={isOpen}
              onToggleFiltersClick={() => setIsOpen(x => !x)}
              {...{ hasFilters }}
            />
          </div>
          <SelectedFilterBar
            active={isOpen}
            onToggleFiltersClick={() => setIsOpen(x => !x)}
            onRemoveTag={handleRemoveTag}
            {...{ tags, lateNight, layoutClassName }}
          />
        </>
      )}
    </>
  )

  function handleRemoveTag(tag) {
    const { type, value } = tag

    if (type === 'hoursPerWeek')
      return onFilterChange({ hoursPerWeekMax: null, hoursPerWeekMin: null })

    if (type === 'latAndLonQuery')
      return onFilterChange({ lat: null, lon: null, customPlaceLabel: null })

    const { value: current } = filtersAndValues[type]
    onFilterChange({ [type]: Array.isArray(current) ? current.filter(x => x !== value) : null })
  }
}

export function FiltersOpenCloseButtonWithDot({ hasFilters, active, onToggleFiltersClick, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentOpenCloseButtonWithDot, layoutClassName)}>
      <FiltersOpenCloseButton onClick={onToggleFiltersClick} {...{ active }} />
      {hasFilters && <div className={styles.dot} />}
    </div>
  )
}

export function FiltersOpenCloseButton({ active, onClick, layoutClassName = undefined, buttonProps = undefined }) {
  const { __ } = useTranslate()

  return (
    <button
      {...buttonProps}
      type='button'
      data-x='click-to-toggle-filter-modal'
      className={cx(styles.componentOpenCloseButton, layoutClassName)}
      {...{ onClick }}
    >
      {active ? __`filter-modal-close-label` : __`filter-modal-open-label`}
      <div className={cx(styles.icon, active && styles.rotate)}>
        <Icon icon={iconButton} layoutClassName={styles.iconLayout} />
      </div>
    </button>
  )
}

function DesktopFilters({ totalJobs, tags, filtersAndValueGroups, onFilterChange, onResetClick, onRemoveTag, isLoggedIn, lateNight, root, layoutClassName, isOpen, onOpenChange, shouldSlideDown }) {
  const {
    floatingRefs,
    isMounted,
    transitionStyles,
    getFloatingProps
  } = useFloatingHooks({ isOpen, setIsOpen: onOpenChange, transform: `translateX(100%)` })

  return (
    <div
      className={cx(styles.componentDesktopFilters, shouldSlideDown && styles.isSlidDown, layoutClassName)}
      data-style-context={lateNight ? cssStyleContext.contextBlack : cssStyleContext.contextWhite}
    >
      <SelectedFilterBar
        layoutClassName={styles.selectedFilterBarLayout}
        active={isOpen}
        onToggleFiltersClick={() => onOpenChange(x => !x)}
        {...{ tags, onRemoveTag, lateNight }}
      />

      {isMounted &&
        <FloatingDesktopFilters {...{ floatingRefs, transitionStyles, getFloatingProps, root }}>
          <FiltersBase
            active={isOpen}
            onCloseClick={() => onOpenChange(false)}
            total={totalJobs}
            layoutClassName={styles.desktopFiltersLayout}
            {...{ onResetClick, lateNight }}
          >
            <FilterContentDesktop {...{ filtersAndValueGroups, onFilterChange, isLoggedIn }} />
          </FiltersBase>
        </FloatingDesktopFilters>
      }
    </div>
  )
}

function MobileFilters({ totalJobs, filtersAndValueGroups, onFilterChange, onResetClick, isLoggedIn, lateNight, root, isOpen, onOpenChange, shouldSlideDown }) {
  const {
    floatingRefs,
    isMounted,
    transitionStyles,
    getFloatingProps
  } = useFloatingHooks({ isOpen, setIsOpen: onOpenChange, transform: `translateY(-100%)` })

  return (
    <div className={cx(styles.componentMobileFilters, shouldSlideDown && styles.isSlidDown)}>
      {isMounted && (
        <FloatingMobileFilters {...{ floatingRefs, transitionStyles, getFloatingProps, root }}>
          <FiltersBase
            active={isOpen}
            onCloseClick={() => onOpenChange(false)}
            total={totalJobs}
            layoutClassName={styles.mobileFiltersLayout}
            onResetClick={handleReset}
            {...{ lateNight }}
          >
            <FilterContentMobile {...{ filtersAndValueGroups, onFilterChange, isLoggedIn }} />
          </FiltersBase>
        </FloatingMobileFilters>
      )}
    </div>
  )

  function handleReset(x) {
    onOpenChange(false)
    onResetClick(x)
  }
}

function FiltersBase({ children, total, active, onCloseClick, onResetClick, layoutClassName, lateNight }) {
  const { __ } = useTranslate()

  return (
    <div
      className={cx(styles.componentBase, layoutClassName)}
      data-style-context={lateNight ? cssStyleContext.contextBlack : cssStyleContext.contextWhite}
    >
      <div className={styles.button}>
        <FiltersOpenCloseButton onClick={onCloseClick} {...{ active }} />
      </div>
      <div
        className={styles.filtersWrapper}
        data-style-context={lateNight ? cssStyleContext.contextBlack : cssStyleContext.contextWhite}
      >
        <div className={styles.filters}>
          {children}
        </div>
      </div>
      <div className={styles.submitAndReset}>
        <Button dataX='submit' onClick={onCloseClick}>
          {__({ total })`filters-total-jobs`}
        </Button>
        <button
          type='button'
          onClick={onResetClick}
          className={styles.resetButton}
          data-x='click-to-reset-filters'
        >
          {__`filters-reset`}
        </button>
      </div>
    </div>
  )
}

function FilterContentDesktop({ filtersAndValueGroups, onFilterChange, isLoggedIn }) {
  return <FilterContentBase {...{ filtersAndValueGroups, onFilterChange, isLoggedIn }} />
}

function FilterContentMobile({ filtersAndValueGroups, onFilterChange, isLoggedIn }) {
  return <FilterContentBase {...{ filtersAndValueGroups, onFilterChange, isLoggedIn }} />
}

function FilterContentBase({ filtersAndValueGroups, onFilterChange, isLoggedIn }) {
  const { all } = filtersAndValueGroups
  const { locationType } = all

  return (
    <>
      {locationType.value === 'all' && <AllFilterGroup filters={filtersAndValueGroups.all} {...{ onFilterChange, isLoggedIn }} />}
      {locationType.value === 'offices' && <OfficesFilterGroup filters={filtersAndValueGroups.offices} {...{ onFilterChange, isLoggedIn }} />}
      {locationType.value === 'shops' && <ShopsFilterGroup filters={filtersAndValueGroups.shops} {...{ onFilterChange, isLoggedIn }} />}
    </>
  )
}

function LocationFilterGroup({ onFilterChange, filters }) {
  const { distanceInKm, lon, lat } = filters
  const { __ } = useTranslate()

  return (
    <>
      <LattitudeLongitudeFilter
        onLattitudeLongitudeChange={handleLattitudeLongitudeChange}
      />

      {lon.value !== 0 && lat.value !== 0 && (
        <FilterFieldRadioGroup
          title={__`filter-distanceInKm-label`}
          value={distanceInKm.value === '' ? null : distanceInKm.value}
          options={distanceInKm.options}
          onChange={handleDistanceChange}
          name='distanceInKm'
        />
      )}
    </>
  )

  function handleDistanceChange(distanceInKm) {
    onFilterChange({ distanceInKm })
  }

  function handleLattitudeLongitudeChange({ lat, lon }) {
    onFilterChange({ lat, lon })
  }
}

function ShopsFilterGroup({ filters, onFilterChange, isLoggedIn }) {
  const { __ } = useTranslate()
  const { role, hoursPerWeekMax, hoursPerWeekMin, lat, lon, distanceInKm, locationType, isInternal, typeOfShop } = filters
  const locationFilters = { lat, lon, distanceInKm }

  return (
    <>
      {isLoggedIn && (
        <FilterFieldRadioGroup
          title={__`filter-internal-label`}
          value={isInternal.value}
          options={isInternal.options}
          onChange={handleIsInternalChange}
          name='isInternal'
        />
      )}
      <FilterFieldRadioGroup
        title={__`filter-type-label`}
        options={locationType.options}
        onChange={handleLocationTypeChange}
        value={locationType.value}
        name='locationType'
      />
      <LocationFilterGroup filters={locationFilters} {...{ onFilterChange }} />
      <FilterFieldRange
        title={__`filter-hoursPerWeek-label`}
        min={hoursPerWeekMin.value}
        max={hoursPerWeekMax.value}
        start={0}
        end={40}
        gap={3}
        onMinChange={hoursPerWeekMin => onFilterChange({ hoursPerWeekMin })}
        onMaxChange={hoursPerWeekMax => onFilterChange({ hoursPerWeekMax })}
      />
      <FilterFieldCheckboxGroup
        title={__`filter-shopRole-label`}
        value={role.value}
        options={role.options}
        onChange={role => onFilterChange({ role })}
        name='role'
      />

      <FilterFieldCheckboxGroup
        title={__`filter-typeOfShop-label`}
        value={typeOfShop.value}
        options={typeOfShop.options}
        onChange={typeOfShop => onFilterChange({ typeOfShop })}
        name='typeOfShop'
      />

    </>
  )

  function handleIsInternalChange(isInternal) {
    onFilterChange({ isInternal })
  }

  function handleLocationTypeChange(locationType) {
    onFilterChange({ locationType })
  }
}

function AllFilterGroup({ filters, onFilterChange, isLoggedIn }) {
  const { __ } = useTranslate()
  const { locationType, isInternal } = filters

  return (
    <>
      {isLoggedIn && (
        <FilterFieldRadioGroup
          title={__`filter-internal-label`}
          value={isInternal.value}
          options={isInternal.options}
          onChange={handleIsInternalChange}
          name='isInternal'
        />
      )}
      <FilterFieldRadioGroup
        title={__`filter-type-label`}
        options={locationType.options}
        onChange={handleLocationTypeChange}
        value={locationType.value}
        name='locationType'
      />
    </>
  )

  function handleIsInternalChange(isInternal) {
    onFilterChange({ isInternal })
  }

  function handleLocationTypeChange(locationType) {
    onFilterChange({ locationType })
  }
}

function OfficesFilterGroup({ filters, onFilterChange, isLoggedIn }) {
  const { __ } = useTranslate()

  const { careerLevel, locationType, isInternal, locationName, department } = filters

  return (
    <>
      {isLoggedIn && (
        <FilterFieldRadioGroup
          title={__`filter-internal-label`}
          value={isInternal.value}
          options={isInternal.options}
          onChange={handleIsInternalChange}
          name='isInternal'
        />
      )}
      <FilterFieldRadioGroup
        title={__`filter-type-label`}
        options={locationType.options}
        onChange={handleLocationTypeChange}
        value={locationType.value}
        name='locationType'
      />
      <FilterFieldCheckboxGroup
        title={'City'}
        value={locationName.value}
        options={locationName.options}
        onChange={handleLocationNameChange}
        name='locationName'
      />
      <FilterFieldCheckboxGroup
        title={__`filter-careerLevel-label`}
        value={careerLevel.value}
        options={careerLevel.options}
        onChange={handleCareerLevelChange}
        name='careerLevel'
      />
      {department && (
        <FilterFieldCheckboxGroup
          title={__`filter-department-label`}
          value={department.value}
          options={department.options}
          onChange={handleDepartmentChange}
          name='department'
        />
      )}
    </>
  )

  function handleCareerLevelChange(careerLevel) {
    onFilterChange({ careerLevel })
  }

  function handleDepartmentChange(department) {
    onFilterChange({ department })
  }

  function handleLocationNameChange(locationName) {
    onFilterChange({ locationName })
  }

  function handleIsInternalChange(isInternal) {
    onFilterChange({ isInternal })
  }

  function handleLocationTypeChange(locationType) {
    onFilterChange({ locationType })
  }
}

function LattitudeLongitudeFilter({ onLattitudeLongitudeChange }) {
  const { __ } = useTranslate()
  const [{ lat, lon, customPlaceLabel }, setQueryString] = useQueryString()
  const { getCurrentPosition, isLoading: isLoadingGeolocation, browserSupportsGeoLocation } = useGeoLocation()

  const [showCustomPlace, setShowCustomPlace] = React.useState(false)

  return (
    <CustomPlaceInputField
      onGetLocation={handleGetMyPlace}
      onLocationSuccess={handleGetPlaceSuccess}
      onClearLocation={handleClearCustomLocation}
      currentSelectedPlaceLabel={customPlaceLabel || __`tag-my-location`}
      hasCustomLocation={lat && lon}
      {...{ isLoadingGeolocation, showCustomPlace, browserSupportsGeoLocation }}
    />
  )

  function handleGetMyPlace() {
    getCurrentPosition(({ lat, lon }) => handleLattitudeLongitudeChange({ lat, lon }))
    setShowCustomPlace(false)
  }

  function handleLattitudeLongitudeChange({ lat, lon }) {
    onLattitudeLongitudeChange({ lat, lon })
  }

  function handleGetPlaceSuccess({ lat, lon, customPlaceLabel }) {
    handleLattitudeLongitudeChange({ lat, lon })
    setQueryString(x => ({ ...x, customPlaceLabel }))
    setShowCustomPlace(false)
  }

  function handleClearCustomLocation() {
    setQueryString(x => ({ ...x, lat: null, lon: null, customPlaceLabel: null }))
  }
}

function CustomPlaceInputField({
  hasCustomLocation,
  currentSelectedPlaceLabel,
  browserSupportsGeoLocation,
  onLocationSuccess: handleLocationSuccess,
  onClearLocation: handleClearLocation,
  onGetLocation: handleGetLocation,
  isLoadingGeolocation
}) {
  const isMountedRef = useIsMountedRef()
  const reportError = useReportError()
  const { __ } = useTranslate()

  const [customPlaceQuery, setCustomPlaceQuery] = React.useState('')
  const debouncedCustomPlaceQuery = useDebounce(customPlaceQuery, 500)
  const [showError, setShowError] = React.useState('')

  const { mutate } = useMutation({
    mutationFn: ({ placeId, placeLabel }) => getPlaceCoordinate({ placeId, placeLabel }),
  })

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [routeMap.api.v1.placesAutocomplete(), debouncedCustomPlaceQuery],
    queryFn: () => getPlaceAutocomplete(debouncedCustomPlaceQuery),
    initialData: { places: [] },
  })

  const { places = [] } = data || {}
  const autocompleteOptions = places.map(({ description, placeId }) => ({ label: description, value: placeId }))

  return (
    <>
      <TextFieldWithAutocomplete
        value={customPlaceQuery}
        id='autocompletions'
        options={autocompleteOptions}
        onSelect={x => setCustomPlaceQuery(x.label)}
        onGetLocation={() => handleGetLocation()}
        onClearLocation={() => handleClearLocation()}
        isLoading={isLoadingGeolocation || isLoading || isFetching || false}
        onInputValueChange={(value) => { setCustomPlaceQuery(value) }}
        onSubmit={({ value, label }) => mutate({ placeId: value, placeLabel: label })}
        {...{
          hasCustomLocation,
          currentSelectedPlaceLabel,
          browserSupportsGeoLocation
        }}
      />
      {Boolean(showError) && <>{showError}</>}
    </>
  )

  async function getPlaceAutocomplete(placeQuery) {
    try {
      const response = await fetch(routeMap.api.v1.placesAutocomplete(), {
        method: 'POST',
        body: JSON.stringify({ placeQuery }),
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      })

      const handledResponse = await handleResponse(response)
      if (!isMountedRef.current) return { places: [] }
      setShowError('')

      return handledResponse
    } catch (e) {
      reportError(e)
      if (!isMountedRef.current) return { places: [] }
      setShowError(__`could-not-autocomplete`)
    }
  }

  async function getPlaceCoordinate({ placeId, placeLabel }) {
    const response = await fetch(routeMap.api.v1.placeCoordinate(), {
      method: 'POST',
      body: JSON.stringify({ placeId }),
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    })

    const { place } = await handleResponse(response)

    if (!place) {
      setShowError(__`no-location-found`)
      return
    }

    const { lat, lon } = place

    handleLocationSuccess({ lat, lon, customPlaceLabel: placeLabel })
    setShowError('')
  }
}

function FloatingDesktopFilters({ children, floatingRefs, transitionStyles, getFloatingProps, root }) {
  return (
    <FloatingPortal id='desktop-filters-root' {...{ root }}>
      <FloatingOverlayBase lockScroll className={styles.componentFloatingDesktopFilters}>
        <div
          className={styles.desktopFiltersInner}
          ref={floatingRefs.setFloating}
          style={transitionStyles}
          {...getFloatingProps()}
        >
          {children}
        </div>
      </FloatingOverlayBase>
    </FloatingPortal>
  )
}

function FloatingMobileFilters({ children, floatingRefs, transitionStyles, getFloatingProps, root }) {
  return (
    <FloatingPortal id='mobile-filters-root' {...{ root }}>
      <FloatingOverlayBase
        lockScroll
        className={styles.componentFloatingMobileFilters}
        ref={floatingRefs.setFloating}
        style={transitionStyles}
        {...getFloatingProps()}
      >
        {children}
      </FloatingOverlayBase>
    </FloatingPortal>
  )
}

function groupFiltersAndValues(filtersAndValues) {
  const {
    locationType, distanceInKm,
    careerLevel, department, role, hoursPerWeekMin,
    hoursPerWeekMax, isInternal, typeOfShop, lat, lon,
    locationName
  } = filtersAndValues

  return {
    shops: { locationType, role, hoursPerWeekMin, hoursPerWeekMax, distanceInKm, lat, lon, isInternal, typeOfShop },
    all: { locationType, isInternal },
    offices: { locationType, careerLevel, department, locationName, isInternal },
  }
}

function useFloatingHooks({ isOpen, setIsOpen, transform }) {
  const { refs, context } = useFloating({ open: isOpen, onOpenChange: setIsOpen })

  const dismiss = useDismiss(context, { escapeKey: true })
  const click = useClick(context, { enabled: false, event: 'mousedown' })
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: 400,
    initial: {
      transform
    },
  })

  const { getFloatingProps } = useInteractions([dismiss, click])

  return { floatingRefs: refs, isMounted, transitionStyles, getFloatingProps }
}

function createTagsData(filtersAndValues, language) {
  const { locationType, hoursPerWeekMin, hoursPerWeekMax, lat, lon, customPlaceLabel, ...filters } = filtersAndValues

  const hoursMin = hoursPerWeekMin.value
  const hoursMax = hoursPerWeekMax.value
  const hoursString = `${hoursMin}-${hoursMax} ${translateSingular(language, `tag-hoursPerWeek-label`)}`

  const tags = Object.entries(filters).reduce(
    (result, [type, item]) => !item.value
      ? result
      : result.concat(
        Array.isArray(item.value)
          ? item.value.map(value => getTagInfo(item, value, type))
          : [getTagInfo(item, item.value, type)]
      ),
    []
  )

  return [
    ...tags,
    ...(lat.value && lon.value
      ? [{
        type: 'latAndLonQuery',
        value: [lat.value, lon.value].join(','),
        label: customPlaceLabel?.value || translateSingular(language, `tag-my-location`)
      }]
      : []
    ),
    ...(isNotHoursPerWeekDefaultValue(hoursMin, hoursMax)
      ? [{ type: 'hoursPerWeek', value: hoursString, label: hoursString }]
      : []
    )
  ]
}

function getTagInfo(item, value, type) {
  return {
    value,
    type,
    label: item.options.find(y => y.value === value)?.label
  }
}
