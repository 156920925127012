import { JobListingListItem } from '/features/buildingBlocks/JobListing'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { Pagination } from '/features/buildingBlocks/Pagination'
import { useClientConfig } from '/machinery/ClientConfig'
import { ShadowCasting } from '/features/pageOnly/ShadowCasting'
import { ButtonGhost } from '/features/buildingBlocks/Button'
import { useContrastingStyle } from '/machinery/StyleContext'
import { useTranslate } from '/machinery/I18n'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'

import cssStyleContext from '/cssGlobal/style-context.css'
import styles from './Results.css'

export function Results({ lateNight, page, jobs, totalJobs, isError, isSuccess, isFetching, onLoadMoreJobs, showJobType }) {
  const { jobsPerPage } = useClientConfig()
  const hasJobs = Boolean(jobs.length)
  const isMounted = useRenderOnMount()
  const contrastingStyleContext = useContrastingStyle({
    theme: lateNight ? cssStyleContext.contextBlack : cssStyleContext.contextWhite,
    white: cssStyleContext.contextBlack,
    black: cssStyleContext.contextBlack
  })

  if (!isMounted) return null

  return (
    <section
      data-x='jobs-overview-list'
      className={styles.component}
      data-style-context={lateNight ? cssStyleContext.contextGray : cssStyleContext.contextWhite}
    >
      <ShadowCasting layoutClassName={styles.shadowCastingLayout} />

      <div className={styles.notifications}>
        {isError && <ErrorMessage {...{ contrastingStyleContext }} />}
        {isSuccess && !hasJobs && <NoResultsMessage {...{ contrastingStyleContext }} />}
      </div>

      <ContainerMd layoutClassName={styles.jobsLayout}>
        {isSuccess && hasJobs && (
          <Jobs {...{ jobs, totalJobs, jobsPerPage, page, lateNight, onLoadMoreJobs, contrastingStyleContext, showJobType }} />
        )}
      </ContainerMd>
    </section>
  )
}

function Jobs({ jobs, totalJobs, jobsPerPage, page, contrastingStyleContext, onLoadMoreJobs, showJobType }) {
  const { __ } = useTranslate()
  const total = totalJobs

  return (
    <div className={styles.componentJobs} data-style-context={contrastingStyleContext}>
      <JobsList {...{ jobs, showJobType }} />

      <Pagination
        label={__({ current: jobs.length, total })`page-x-of-jobs`}
        itemsPerPage={jobsPerPage}
        currentSize={jobs.length}
        total={totalJobs}
        {...{ page }}
      />

      {(totalJobs / jobsPerPage) > page && (
        <ShowMoreJobsButton
          onClick={onLoadMoreJobs}
          layoutClassName={styles.showMoreJobsButtonLayout}
        />
      )}
    </div>
  )
}

function JobsList({ jobs, showJobType }) {
  return (
    <List>
      {jobs.map(({ _source: job }) =>
        <JobListingListItem key={job.jobAdId} {...{ job, showJobType }} />
      )}
    </List>
  )
}

function ShowMoreJobsButton({ onClick, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <ButtonGhost
      dataX='show-more-jobs'
      {...{ onClick, layoutClassName }}
    >
      {__`filter-pagination-button`}
    </ButtonGhost>
  )
}

function NoResultsMessage({ contrastingStyleContext }) {
  const { __ } = useTranslate()
  return (
    <p
      className={styles.componentNoResultsMessage}
      data-style-context={contrastingStyleContext}
      aria-label={__`jobs-not-results-message`}
    >
      {splitLines(__`jobs-not-results-message`)}
    </p>
  )
}


function ErrorMessage({ contrastingStyleContext }) {
  const { __ } = useTranslate()
  return (
    <p
      className={styles.componentErrorMessage}
      data-style-context={contrastingStyleContext}
      aria-label={__`jobs-overview-error`}
    >
      {splitLines(__`jobs-overview-error`)}
    </p>
  )
}

function List({ children }) {
  return (
    <ul className={styles.componentList}>
      {children}
    </ul>
  )
}

function splitLines(string) {
  return string.split('\n').map((str, i) => <span key={i}>{str}</span>)
}
