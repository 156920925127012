import { useOnChange } from '/machinery/useOnChange'
import { unlerp } from '@kaliber/math'

import styles from './FilterField.css'

export function FilterFieldCheckboxGroup({ title, onChange, value, options, name }) {
  return (
    <div className={styles.componentCheckboxGroup}>
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.options}>
        {options.map((option, i) => {
          const id = `${name}__${i}`

          return (
            <div key={id} className={styles.checkboxField}>
              <input
                className={styles.checkboxInput}
                type='checkbox'
                value={option.value}
                aria-describedby={`${name}-description ${name}-error`}
                onChange={handleChange(option.value)}
                checked={Array.isArray(value) && value.includes(option.value)}
                {...{ name, id }}
              />
              <label htmlFor={id} className={styles.label}>
                {option.label}
                {option.total ? (` (${option.total})`) : '(0)'}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )

  function handleChange(changedValue) {
    return e => {
      const newValue =
        !Array.isArray(value) ? [changedValue] :
        value.includes(changedValue) ? value.filter(x => x !== changedValue) :
        value.concat(changedValue)

      onChange(newValue)
    }
  }
}

export function FilterFieldRadioGroup({ value, title, options, name, onChange }) {
  return (
    <div className={styles.componentRadioGroup}>
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.options}>
        {options.map((option, i) => {
          const id = `${name}_${i}`

          return (
            <div key={id} className={styles.radioField}>
              <input
                type='radio'
                value={option.value || ''}
                checked={value === option.value}
                className={styles.radioInput}
                onChange={handleChange}
                {...{ name, id }}
              />
              <label htmlFor={id} className={styles.label}>{option.label}</label>
            </div>
          )
        })}
      </div>
    </div>
  )


  function handleChange(e) {
    onChange(e.currentTarget.value)
  }
}

export function FilterFieldText({ title, placeholder, onChange, value }) {
  return (
    <div className={styles.componentText}>
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.textField}>
        <input
          type='text'
          {...{ placeholder, value }}
          onChange={handleChange}
          className={styles.textInput}
        />
      </div>
    </div>
  )

  function handleChange(x) {
    onChange(x.target.value)
  }
}

export function FilterFieldRange({ title, min, max, onMinChange, onMaxChange, start, end, gap }) {
  return (
    <div className={styles.componentRange}>
      <h4 className={styles.title}>{title}</h4>
      <RangeField
        {...{ min, max, onMinChange, onMaxChange, start, end, gap }}
        layoutClassName={styles.rangeFieldLayout}
      />
    </div>
  )
}

function RangeField({ min: initialMin, max: initialMax, start, end, onMinChange, onMaxChange, gap, layoutClassName }) {
  const [min, setMin] = React.useState(initialMin)
  const [max, setMax] = React.useState(initialMax)

  useOnChange({ ifChanged: initialMin, callback: setMin })
  useOnChange({ ifChanged: initialMax, callback: setMax })

  return (
    <div className={cx(styles.componentRangeField, layoutClassName)}>
      <RangeLabels {...{ start, end }} layoutClassName={styles.rangeLabelsLayout} />
      <RangeSpan {...{ min, max, start, end }} layoutClassName={styles.rangeSpanLayout} />
      <RangeTrack layoutClassName={styles.rangeTrackLayout} />
      <RangeInputs
        {...{ min, max, start, end }}
        layoutClassName={styles.rangeInputsLayout}
        onMinChange={value => {
          const newValue = Math.min(max - gap, value)
          setMin(newValue)
          onMinChange(newValue)
        }}
        onMaxChange={value => {
          const newValue = Math.max(min + gap, value)
          setMax(newValue)
          onMaxChange(newValue)
        }}
      />
      <RangeIndicators
        {...{ min, max, start, end }}
        layoutClassName={styles.rangeIndicatorsLayout}
      />
    </div>
  )
}

function RangeLabels({ start, end, layoutClassName }) {
  return (
    <div className={cx(styles.componentRangeLabels, layoutClassName)}>
      <RangeLabel position='start' label={start} />
      <RangeLabel position='end' label={end} />
    </div>
  )
}

function RangeLabel({ position, label }) {
  return <div className={cx(styles.componentRangeLabel, styles[position])}>{label}</div>
}

function RangeTrack({ layoutClassName }) {
  return <div className={cx(styles.componentRangeTrack, layoutClassName)} />
}

function RangeSpan({ min, max, start, end, layoutClassName }) {
  const startProgress = unlerp({ start, end, input: min })
  const endProgress = unlerp({ start, end, input: max })

  return (
    <div className={cx(styles.componentRangeSpan, layoutClassName)}>
      <div
        className={cx(styles.rangeSpanLine, styles.rangeSpanLineLayout)}
        style={{
          left: asPercentage(startProgress),
          width: asPercentage(endProgress - startProgress),
        }}
      />
    </div>
  )
}

function RangeInputs({ min, max, start, end, onMinChange, onMaxChange, layoutClassName }) {
  return (
    <div className={cx(styles.componentRangeInputs, layoutClassName)}>
      <RangeInput value={min} min={start} max={end} onChange={onMinChange} />
      <RangeInput value={max} min={start} max={end} onChange={onMaxChange} />
    </div>
  )
}

function RangeInput({ value, onChange, min, max }) {
  return (
    <input
      type='range'
      {...{ value, min, max }}
      className={styles.componentRangeInput}
      onChange={e =>
        onChange(Number(e.currentTarget.value))
      }
    />
  )
}

function RangeIndicators({ min, max, start, end, layoutClassName }) {
  return (
    <div className={cx(styles.componentRangeIndicators, layoutClassName)}>
      <RangeIndicator label={min} progress={unlerp({ start, end, input: min })} />
      <RangeIndicator label={max} progress={unlerp({ start, end, input: max })} />
    </div>
  )
}

function RangeIndicator({ progress, label }) {
  return (
    <span className={styles.componentRangeIndicator} style={{ left: asPercentage(progress) }}>
      {label}
    </span>
  )
}

function asPercentage(value) {
  return `${value * 100}%`
}
